h1.logo-name {
    text-align: left;
    font-size: 62px;
    letter-spacing: -5px;
}

.navbar-form-select {
    margin-top: 13px;
}

/* Gender select */
.gender-select label {
    display: inline-block;
    text-align: center;
}
.gender-select label input {
    display: none;
}
.gender-select label img {
    width: 50px;
    height: auto;
    border: 3px solid #E7EAEC;
    border-radius: 50%;
}
.gender-select label input:checked + img, .gender-select label:hover img {
    border-color: #1ab394;
}

/* Cropper */
.img-preview-sm {
    width: 200px;
    height: 267px;
}
.image-crop {
    max-height: 500px;
}

/* Tables */
.client-avatar div {
    border-radius: 50%;
    max-height: 28px;
    max-width: 28px;
    overflow: hidden;
}
.client-avatar img {
    width: 28px;
    height: auto;
    border-radius: 0;
}
td.action-buttons {
    white-space: nowrap;
}

/* Datatime picker */
.input-datetime-wrapper {
    position: relative;
}

.bootstrap-datetimepicker-widget {
    font-size: 14px;
}

/* Rating */
.rating {
    direction: rtl;
    font-size: 30px;
    unicode-bidi: bidi-override;
}
.rating span.star {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
}
.editable-rating span.star:hover {
    cursor: pointer;
}
.rating span.star::before {
    color: #777777;
    content: "\f006";
    padding-right: 5px;
}
.rating span.star.active::before, .rating span.star.active ~ span.star::before,
.editable-rating span.star:hover::before, .editable-rating span.star:hover ~ span.star::before {
    color: #e3cf7a;
    content: "\f005";
}

/* Mainpage requests */
.forum-avatar div {
    border-radius: 50%;
    max-height: 48px;
    max-width: 48px;
    overflow: hidden;
}
.forum-avatar img {
    width: 48px;
    height: auto;
    border-radius: 0;
}

/* Profile */
.user-action-buttons > .row > div {
    padding-left: 3px;
    padding-right: 3px;
}

/* dataTables */
table.dataTable {
    width: 100%;
    max-width: 100% !important;
}
table.dataTable td, table.dataTable th {
    box-sizing: border-box;
}
table.dataTable th input.form-control {
    width: 100%;
    font-weight: normal;
    margin-bottom: 5px;
}
table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
    padding-right: 22px;
}
table.dataTable tfoot > tr > th {
    vertical-align: baseline;
}

select.input-sm {
    padding: 3px 12px;
}

.soft-hidden {
    display: none;
}
